import React, { useContext } from 'react';
import './index.scss';
import { faExternalLink, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SettingsContext } from 'context/SettingsContext';

const Headband = () => {
  const { showHeadband, setShowHeadband } = useContext(SettingsContext);
  if (!showHeadband) return null;
  return (
    <div className='Headband'>
      <div></div>
      <div className='headband-content'>
        <div className='headband-content-text'>
          <span>
            <a
              href='https://docs.dusa.io/concepts/rewards'
              target='_blank'
              rel='noopener noreferrer'
            >
              Discover more about 🔥Incentives🔥 on our docs{' '}
              <FontAwesomeIcon icon={faExternalLink} />
            </a>
          </span>
        </div>
      </div>
      <div className='hide-headband-container'>
        <FontAwesomeIcon icon={faX} onClick={() => setShowHeadband(false)} />
      </div>
    </div>
  );
};

export default Headband;
