import { Token as _Token } from '@dusalabs/sdk';
import { Args, ICallData as _ICallData } from '@massalabs/massa-web3';

export class Token extends _Token {
  constructor(
    chainId: number,
    address: string,
    decimals: number,
    public readonly logoURI: string,
    public readonly symbol: string,
    public readonly name: string
  ) {
    super(chainId, address, decimals, symbol, name);
  }
}

export type TokenInfo = {
  token: Token;
  balance: bigint;
  price: number;
};

export interface PoolLiquidity {
  token0: Token;
  token1: Token;
  binStep: number;
  amount0: bigint;
  amount1: bigint;
  fees0: bigint;
  fees1: bigint;
}

export interface Deposits {
  amount0: bigint;
  amount1: bigint;
}

export interface Bin {
  id: number;
  amount0: bigint;
  amount1: bigint;
  amountLBT: bigint;
  accToken0PerShare: bigint;
  accToken1PerShare: bigint;
  value: number;
}

export interface ClaimInfo {
  id: number;
  amounts: { amountX: bigint; amountY: bigint };
}

export interface Vault {
  address: string;
  bins: Bin[];
  type: number;
}

export enum LiquidityShape {
  // AUTONOMOUS,
  SPOT,
  CURVE,
  BID_ASK
  // WIDE
}

export interface Transaction {
  function: string;
  status: 'confirmed' | 'failed' | 'pending' | 'cancelled' | 'unknown';
  from: string;
  to: string;
  hash: string;
  timestamp: number;
  description: string;
}

export enum TimeWindow {
  Day = 1,
  Week = 7,
  Month = 30
}

export enum DayWindow {
  WEEK = 7,
  MONTH = 30,
  QUARTER = 90,
  HALF_YEAR = 180
}

// ensure `parameter` is of type `Args` and not `Array<number>`
export type ICallData = Omit<_ICallData, 'parameter'> & { parameter: Args };

export type Allowance = 'increase' | 'increaseMax';

export interface Proposal {
  id: number;
  title: string;
  description: string;
  forVotes: number;
  againstVotes: number;
  abstainVotes: number;
  timestamp: number;
  status:
    | 'active'
    | 'ended'
    | 'canceled'
    | 'defeated'
    | 'succeeded'
    | 'queued'
    | 'expired'
    | 'executed';
}

export interface Epoch {
  id: number;
  from: Date;
  to: Date;
}

export interface Role {
  id: string;
  name: string;
}
export interface DiscordUser {
  id: string;
  username: string;
  role: string;
  avatar: string;
  global_name: string;
}
