import React from 'react';
import { Link } from 'react-router-dom';
import PoolName from 'components/PoolName';
import { routeNames } from 'routes';
import { FEATURE_FLAGS } from 'utils/config';
import { getMasIfWmas } from 'utils/coreMethods';
import { formatNumberWithSubscriptZeros, printBigintIsh } from 'utils/methods';
import { PoolLiquidity } from 'utils/types';
import './Pools.scss';

const PoolOwnItem = ({
  pool,
  isAutonomous
}: {
  pool: PoolLiquidity;
  isAutonomous: boolean;
}) => {
  const { token0, token1, binStep } = pool;
  const tokenA = getMasIfWmas(token0);
  const tokenB = getMasIfWmas(token1);

  return (
    <React.Fragment key={token0.symbol + binStep}>
      <Link
        to={routeNames.liquidityItem
          .replace(':token0', token0.address)
          .replace(':token1', token1.address)
          .replace(':binStep', binStep.toString())}
        className='asset-item'
      >
        <PoolName token0={tokenA} token1={tokenB} binStep={binStep} />
        <div className='asset-details'>
          <div>
            <div>Balance</div>
            <div className='asset-token-amount'>
              <span>
                {tokenA.symbol}{' '}
                {formatNumberWithSubscriptZeros(
                  printBigintIsh(tokenA, pool.amount0)
                )}
              </span>
              <span>-</span>
              <span>
                {tokenB.symbol}{' '}
                {formatNumberWithSubscriptZeros(
                  printBigintIsh(tokenB, pool.amount1)
                )}
              </span>
            </div>
          </div>
          <div>
            <div>Earned fees</div>
            <div className='asset-token-amount'>
              {isAutonomous ? (
                <span className='info-fees-al'>
                  Fees in autonomous liquidity are automatically claimed
                </span>
              ) : (
                <>
                  <span>
                    {tokenA.symbol}{' '}
                    {formatNumberWithSubscriptZeros(
                      printBigintIsh(tokenA, pool.fees0)
                    )}
                  </span>
                  <span>-</span>
                  <span>
                    {tokenB.symbol}{' '}
                    {formatNumberWithSubscriptZeros(
                      printBigintIsh(tokenB, pool.fees1)
                    )}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </Link>
    </React.Fragment>
  );
};

export const PoolsOwn = ({
  poolsOwn,
  isAutonomous
}: {
  poolsOwn: PoolLiquidity[];
  isAutonomous: boolean;
}) => {
  return (
    <div className='PoolsOwn'>
      {FEATURE_FLAGS.AUTONOMOUS_LIQUIDITY && (
        <div className='autonomous-manual-div'>
          {isAutonomous ? 'Autonomous' : 'Manual'} Liquidity
        </div>
      )}
      <div className='assets-owned-container'>
        {poolsOwn.map((pool, index) => (
          <React.Fragment key={pool.binStep + '' + index}>
            <PoolOwnItem pool={pool} isAutonomous={isAutonomous} />
            {index < poolsOwn.length - 1 && <div className='line'></div>}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default PoolsOwn;
