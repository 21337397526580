import { useEffect, useState } from 'react';
import { FEATURE_FLAGS } from 'utils/config';
import {
  abs,
  formatNumberWithSubscriptZeros,
  printBigintIsh,
  toToken
} from 'utils/methods';
import { getRecentTransactions } from 'utils/storage';
import { trpc } from 'utils/trpc';
import { Transaction } from 'utils/types';

const useFetchTxs = (userAddress: string) => {
  const [recentTxs, setRecentTxs] = useState<Transaction[]>([]);
  const [isGetTxsLoading, setIsGetTxsLoading] = useState(true);

  const { data: operations, refetch } = trpc.getOperations.useQuery(
    { address: userAddress, take: 100 },
    { enabled: !!userAddress }
  );

  const sortTxs = (txs: Transaction[]) =>
    txs.sort((a, b) => b.timestamp - a.timestamp);

  const refreshTxsFn = () => {
    setRecentTxs([]);
    setIsGetTxsLoading(true);
    refetch().then(() => fetch());
  };

  const getLiquidityTxsFromDB = () => {
    if (!operations?.liquidityTxs) throw new Error();

    const opsLiquidity: Transaction[] = [];
    for (const op of operations.liquidityTxs) {
      const token0 = toToken(op.pool.token0);
      const token1 = toToken(op.pool.token1);
      const amount0 = formatNumberWithSubscriptZeros(
        printBigintIsh(token0, abs(BigInt(op.amount0)))
      );
      const amount1 = formatNumberWithSubscriptZeros(
        printBigintIsh(token1, abs(BigInt(op.amount1)))
      );

      const isAdd = BigInt(op.amount0) > 0n || BigInt(op.amount1) > 0n;

      const description = `${isAdd ? 'Add' : 'Remove'} ${amount0} ${
        token0.symbol
      } and ${amount1} ${token1.symbol}`;

      opsLiquidity.push({
        function: (isAdd ? 'Add' : 'Remove') + ' Liquidity',
        status: 'confirmed',
        to: op.poolAddress,
        hash: op.txHash,
        timestamp: new Date(op.timestamp).getTime(),
        description,
        from: userAddress
      });
    }

    return opsLiquidity;
  };
  const getSwapTxsFromDB = () => {
    if (!operations?.liquidityTxs) throw new Error();

    const opsSwap: Transaction[] = [];
    for (const op of operations.swapTxs) {
      const token0 = toToken(op.pool.token0);
      const token1 = toToken(op.pool.token1);
      const [tokenIn, tokenOut] = op.swapForY
        ? [token0, token1]
        : [token1, token0];
      const amountIn = formatNumberWithSubscriptZeros(
        printBigintIsh(tokenIn, op.amountIn)
      );
      const amountOut = formatNumberWithSubscriptZeros(
        printBigintIsh(tokenOut, op.amountOut)
      );
      const description = `Swap ${amountIn} ${tokenIn.symbol} for ${amountOut} ${tokenOut.symbol}`;

      opsSwap.push({
        function: 'Swap',
        status: 'confirmed',
        to: op.poolAddress,
        hash: op.txHash,
        timestamp: new Date(op.timestamp).getTime(),
        description,
        from: userAddress
      });
    }

    return opsSwap;
  };

  const fetch = () => {
    if (!operations) {
      setRecentTxs([]);
      setIsGetTxsLoading(false);
      return;
    }

    const getTxs = async () => {
      const opsLiquidity: Transaction[] = FEATURE_FLAGS.TXS_FROM_DB
        ? getLiquidityTxsFromDB()
        : [];
      const opsSwap: Transaction[] = FEATURE_FLAGS.TXS_FROM_DB
        ? getSwapTxsFromDB()
        : [];

      const txsFetched = [...opsLiquidity, ...opsSwap];
      const txsFromLocalStorage = getRecentTransactions(userAddress);
      const txsFromLocalStorageHashes = new Set(
        txsFromLocalStorage.map((tx) => tx.hash)
      );
      const txsFetchedFiltered = txsFetched.filter(
        (tx) => !txsFromLocalStorageHashes.has(tx.hash)
      );
      const opsConcat = txsFetchedFiltered.concat(txsFromLocalStorage);
      const ops = sortTxs(opsConcat);
      setRecentTxs(ops);
      setIsGetTxsLoading(false);
    };
    getTxs();
  };

  useEffect(() => {
    fetch();
  }, [operations]);

  return { recentTxs, isGetTxsLoading, refreshTxsFn };
};

export default useFetchTxs;
