import React, { useContext, useState } from 'react';
import { TokenAmount } from '@dusalabs/sdk';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx/lite';
import TokenModal from 'components/TokenModal';
import { AccountWrapperContext } from 'context/AccountWrapperContext';
import {
  formatNumberWithSubscriptZeros,
  printBigintIsh,
  printUSD,
  tokenAmountToSignificant,
  unFormatPrintedNumber
} from 'utils/methods';
import { MASSA } from 'utils/tokens';
import { Token } from 'utils/types';
import NumberInput from './NumberInput';
import './index.scss';

export interface TokenInputProps {
  headerText: 'From' | 'To';
  token: Token | undefined;
  otherToken?: Token | undefined;
  changeToken: (t: Token) => void;
  quantity: string | undefined;
  changeQuantity: (q?: string) => void;
  balance: bigint;
  isChangeable?: boolean;
  dollarValue?: number;
  disabled?: boolean;
  isError?: boolean;
}

const TokenInput = (props: TokenInputProps) => {
  const {
    headerText,
    token,
    otherToken = undefined,
    changeToken,
    quantity,
    changeQuantity,
    balance,
    isChangeable = true,
    dollarValue,
    disabled = false,
    isError = false
  } = props;
  const [showModal, setShowModal] = useState(false);
  const { connected } = useContext(AccountWrapperContext);
  const balanceFormatted = token
    ? formatNumberWithSubscriptZeros(printBigintIsh(token, balance))
    : '';

  const q = quantity ? unFormatPrintedNumber(quantity) : undefined;

  return (
    <div
      className={clsx('TokenInput', isError && 'inputError')}
      aria-disabled={disabled}
    >
      <div className='select-amount'>
        <NumberInput {...props} />
        <div
          className={clsx(
            'select-token',
            isChangeable && 'isChangeable',
            !token && 'selectable'
          )}
          onClick={() => {
            if (isChangeable) {
              setShowModal(true);
              document.body.style.overflow = 'hidden';
            }
          }}
        >
          {token ? (
            <>
              <img src={token?.logoURI} alt='logo' className='logo' />
              <span>{token?.symbol}</span>
            </>
          ) : (
            <span style={{ whiteSpace: 'nowrap' }}>Select token</span>
          )}
          {isChangeable && <FontAwesomeIcon icon={faAngleDown} />}
        </div>
      </div>
      <div className='details-token'>
        <div className='dollar-value'>
          {dollarValue && !disabled && q
            ? `$${printUSD(dollarValue * Number(q))}`
            : ''}
        </div>
        {connected && token?.address !== undefined && (
          <div className='balance'>
            <div
              className='balance-value'
              onClick={() =>
                changeQuantity(
                  tokenAmountToSignificant(
                    new TokenAmount(
                      token,
                      token.equals(MASSA) ? balance - BigInt(10 ** 9) : balance
                    )
                  )
                )
              }
            >
              <span>Balance:</span>
              <span>{balanceFormatted}</span>
            </div>
          </div>
        )}
      </div>
      {showModal ? (
        <TokenModal
          activeToken={token}
          changeActiveToken={changeToken}
          showModal={showModal}
          setShowModal={setShowModal}
          otherTokenSelected={otherToken}
          isFirstInput={headerText === 'From'}
        />
      ) : null}
    </div>
  );
};

export default TokenInput;
