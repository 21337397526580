import { getWmasIfMas } from 'utils/coreMethods';
import { tokens as defaultTokens } from 'utils/tokens';
import { trpc } from 'utils/trpc';
import { Token } from 'utils/types';

export const useTokenValues = (tokens?: Token[]) => {
  const tkns = tokens || defaultTokens;
  // prettier-ignore
  const tokensParam = tkns.map((t) => ({tokenAddress: getWmasIfMas(t).address, tokenDecimals: t.decimals}));
  const tokenValuesBatchQuery = trpc.useQueries((t) =>
    tokensParam.map((tok) => t.getTokenValue(tok))
  );
  const tokenValues = tokenValuesBatchQuery.map((q) => q.data || 0);

  return tokenValues;
};
