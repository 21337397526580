import { IS_BUILDNET } from './config';
import { PUR, WMAS, tokens } from './tokens';
import { Token } from './types';

export interface Pool {
  token0: Token;
  token1: Token;
  binStep: number;
}

export const pools: Pool[] = [
  {
    //  WMAS - USDC
    token0: tokens[0],
    token1: tokens[1],
    binStep: 20
  },
  {
    // WETH - WMAS
    token0: tokens[3],
    token1: tokens[0],
    binStep: 15
  },
  {
    // DAI - USDC
    token0: tokens[2],
    token1: tokens[1],
    binStep: 1
  },
  {
    // USDT - USDC
    token0: tokens[5],
    token1: tokens[1],
    binStep: 1
  },
  {
    // WETH.b - WETH.e
    token0: tokens[4],
    token1: tokens[3],
    binStep: 1
  }
];

!IS_BUILDNET && pools.splice(3, 0, { token0: PUR, token1: WMAS, binStep: 100 });
