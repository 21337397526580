import { SCALE_OFFSET } from './constants';
import { MASSA, WMAS } from './tokens';
import { Token } from './types';

export const getMasIfWmas = (token: Token): Token => {
  if (token.equals(WMAS)) return MASSA;
  else return token;
};

export const getWmasIfMas = (token: Token): Token => {
  if (token.equals(MASSA)) return WMAS;
  else return token;
};

const mulShiftRoundDown = (a: bigint, b: bigint): bigint => {
  return (a * b) >> BigInt(SCALE_OFFSET);
};

export const calculateAmount = (
  accTokenPerShare: bigint,
  balance: bigint,
  debt: bigint
): bigint => mulShiftRoundDown(accTokenPerShare, balance) - debt;
